import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ["chart"]
  
  connect() {
    this.compareCharts = {}
    this.chartTargets.forEach(target => {
      this.fetchAndRenderChart(target)
    })
  }

  disconnect() {
    Object.values(this.compareCharts).forEach(chart => {
      if (chart) {
        chart.destroy()
      }
    })
    this.compareCharts = {}
  }

  fetchAndRenderChart(target) {
    const type = target.dataset.type

    fetch(`/comparison_chart_data?chart_type=${type}`)
      .then(response => response.json())
      .then(data => {
        this.updateChart(target, data)
      })
      .catch(error => console.error(error))
  }

  updateChart(target, data) {
    const type = target.dataset.type
    const options = this.getCommonChartOptions()

    options.series = data.series
    options.xaxis.categories = data.categories

    if (this.compareCharts[type]) {
      this.compareCharts[type].updateOptions({
        series: options.series,
        xaxis: { categories: options.xaxis.categories }
      })
    } else {
      this.compareCharts[type] = new ApexCharts(target, options)
      this.compareCharts[type].render()
    }

  }

  getCommonChartOptions() {
    return {
      chart: {
        height: "100%",
        maxWidth: "100%",
        type: "line",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        }
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        y: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -26
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'top',
        horizontalAlign: 'right', 
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Inter, sans-serif',
        markers: {
          size: 8,
          shape: 'square',
          strokeWidth: 1,
        }
      },
      xaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true
      },
    }
  }

}