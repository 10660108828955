import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ["chartMiscMain"]
  static CUSTOMIZE_TIMEOUT = 5000

  connect() {
    this.isCustomizingLabels = false
    this.customizationTimer = null
    this.initializeMiscMainChart()
  }

  disconnect() {
    this.cleanup()
  }

  cleanup() {
    clearTimeout(this.customizationTimer)
    this.isCustomizingLabels = false
    
    if (this.chartMiscMain) {
      this.chartMiscMain.destroy()
      this.chartMiscMain = null
    }
  }

  initializeMiscMainChart() {
    try {
      const series = JSON.parse(this.chartMiscMainTarget.dataset.series)
      const options = this.getChartOptions(series)
      
      this.chartMiscMain = new ApexCharts(this.chartMiscMainTarget, options)
      this.chartMiscMain.render()
    } catch (error) {
      console.error('Error initializing main chart:', error)
    }
  }

  getChartOptions(series) {
    return {
      series,
      chart: {
        type: 'bar',
        height: 600,
        stacked: true,
        toolbar: { show: false },
        events: {
          mounted: (chartContext) => this.safeCustomizeLabels(chartContext),
          updated: (chartContext) => this.handleChartUpdate(chartContext),
        }, 
        // animations: {
        //   enabled: false,
        // }
      },
      xaxis: {
        min: -5, 
        show: false,
        categories: series[0].data.map((record, index) => record.x),
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '13px',
            fontFamily: 'ui-sans-serif, system-ui, -apple-system'
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '60%',
          columnWidth: '90%',
          dataLabels: {
            position: 'top',
            total: {
              enabled: true,
              offsetX: 3,
              offsetY: 1,
              formatter: function (val, opts) {
                return opts.globals.stackedSeriesTotals[opts.dataPointIndex].toLocaleString();
              },
              style: {
                fontSize: '13px',
                fontWeight: 500,
                fontFamily: 'ui-sans-serif, system-ui, -apple-system'
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      colors: ['#8B5CF6', '#EAB308'],
      legend: {
        position: 'top',
        horizontalAlign: 'center',
      },
      tooltip: {
        x: {
          show: false
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }
      }
    };
  }

  handleChartUpdate(chartContext) {
    if (this.isCustomizingLabels) return

    this.safeCustomizeLabels(chartContext)
  }

  async safeCustomizeLabels(chartContext) {
    if (this.isCustomizingLabels) {
      console.warn('Label customization already in progress')
      return
    }

    try {
      this.isCustomizingLabels = true
      
      this.customizationTimer = setTimeout(() => {
        this.isCustomizingLabels = false
        console.warn('Label customization timed out')
      }, this.constructor.CUSTOMIZE_TIMEOUT)

      await this.customizeLabels(chartContext)
    } catch (error) {
      console.error('Error during label customization:', error)
    } finally {
      clearTimeout(this.customizationTimer)
      this.isCustomizingLabels = false
    }
  }

  async customizeLabels(chartContext) {
    const svgElement = this.chartMiscMainTarget.querySelector('svg')
    if (!svgElement) {
      throw new Error('SVG element not found')
    }
    svgElement.style.overflow = 'inherit'

    const labels = svgElement.querySelectorAll('.apexcharts-yaxis-label')
    const visibleSeries = chartContext.w.config.series.find(s => s.data?.length > 0)
    
    if (!visibleSeries?.data) {
      labels.forEach(label => label.remove())
      return
    }

    const seriesData = visibleSeries.data
    const svgNamespace = "http://www.w3.org/2000/svg"

    labels.forEach((label, index) => {
      const matchingData = seriesData[index]
      if (!matchingData) return

      const link = this.createSvgLink(svgNamespace, matchingData)
      const img = this.createSvgImage(svgNamespace, matchingData, label)
      
      link.appendChild(img)
      label.setAttribute('x', "-50");
      label.parentNode.appendChild(link)
    })
  }

  createSvgLink(svgNamespace, matchingData) {
    const link = document.createElementNS(svgNamespace, "a")
    link.setAttributeNS(null, 'href', `/en/onlyfans/${matchingData.username}`)
    link.setAttributeNS(null, 'target', '_blank')
    return link
  }

  createSvgImage(svgNamespace, matchingData, label) {
    const img = document.createElementNS(svgNamespace, "image")
    const yAttr = label.getAttribute('y') || "0"

    img.setAttributeNS(null, 'href', matchingData.avatar_url)
    img.setAttribute('width', '36')
    img.setAttribute('height', '48')
    img.setAttribute('clip-path', 'circle(44%)')
    img.setAttribute('x', "-45")
    img.setAttribute('y', parseFloat(yAttr) - 27)
    img.classList.add(
      'rounded-full', 
      'cursor-pointer', 
      'hover:opacity-80', 
      'apexcharts-text', 
      'apexcharts-yaxis-label'
    )

    const title = document.createElementNS(svgNamespace, "title")
    title.textContent = matchingData.x
    img.appendChild(title)

    return img
  }
}